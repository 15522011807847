import logo from './logo.svg';
import './App.css';
import Home from './Comnponents/Home/Home';
import Slider from './Comnponents/Slider/Slider';
import Articles from './Comnponents/Articles/Articles';
import Token from './Comnponents/Token/Token';

function App() {
  return (
    <div >
      <Home/>
      <Slider/>
      <Articles/>
      <Slider/>
      <Token/>
    </div>
  );
}

export default App;
