import React from 'react'

const Home = () => {
    return (
        <div className='min-h-[100vh] w-[100vw] bg-main'>
            <p className='text-[#ffffff] text-[50px] md:text-[100px] text-bold text-center pt-[100px] mb-[50px]'>Katyusha </p>
            <p className='text-[#ffffff] text-center text-[30px] md:text-[40px] px-[10%] md:px-[20%]'>This is the first cub of Louis (father) and Ding Ding (mother) brought to the Moscow Zoo after President Xi Jinping of the People's Republic of China visited Russia in 2019. This is the first panda born in Russia on August 24, 2023 .</p>
            <div className='flex w-full justify-center mt-[50px] items-center'>
                <a href="https://en.namu.wiki/w/%EC%B9%B4%EC%B8%84%EC%83%A4(%ED%8C%90%EB%8B%A4)">
                    <img className='w-[400px] min-w-[300px]' src={require('../../Assets/post.png')} />
                </a>
            </div>
            <div className='flex md:flex-row flex-col w-full justify-center gap-6 mt-[50px] items-center pb-8'>
                <a href='https://twitter.com/katyushasol'>
                    <img className='w-[300px] ' src={require('../../Assets/twitter.png')} />
                </a>
                <a href='https://t.me/katyushasolana'>
                    <img className='w-[300px] ' src={require('../../Assets/telegram.png')} />
                </a>
            </div>
        </div>
    )
}

export default Home