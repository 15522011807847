import React from 'react'

const Articles = () => {
  return (
    <div className='min-h-[90vh] w-[100vw] bg-main2'>
        <p className='text-[#ffffff] text-[50px] md:text-[100px] text-bold text-center pt-[100px] mb-[50px]'> ARTICLES</p>
                   
        <div className='flex md:flex-row flex-col w-full justify-center gap-6 ] items-center pb-20 px-[10%]'>
                <a href='http://en.people.cn/n3/2024/0826/c90000-20210387.html'>
                    <img className='w-[600px] ' src={require('../../Assets/Article1.png')} />
                </a>
                <a href='https://tass.com/society/1789511'>
                    <img className='w-[600px] ' src={require('../../Assets/Article2.png')} />
                </a>
            </div>
    </div>
  )
}

export default Articles