import React from 'react'
import Marquee from "react-fast-marquee";

const Slider = () => {
    return (
        <div className='bg-[#022811] w-[100vw] border-y-4 border-[#6AA742]'>
            <Marquee>
                <div className='h-[90px] md:h-[140px] flex justify-center items-center gap-8 md:gap-32'>
                    <div className='flex items-center gap-8 md:gap-10'>
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s1.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s3.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s4.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s5.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s1.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s3.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s4.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s5.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s1.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s3.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s4.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s5.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s1.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s3.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s4.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                        <img className='h-[70px] md:h-[80px]'  src={require('../../Assets/s5.png')} />
                        <img  className='h-[70px] md:h-[80px]' src={require('../../Assets/s2.png')} />
                    </div>
                </div>
            </Marquee>
        </div>
    )
}

export default Slider