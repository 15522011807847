import React from 'react'

const Token = () => {
  return (
    <div className='min-h-[90vh] w-[100vw] bg-main2 max-w-[100vw] overflow-clip'>
        <p className='text-[#ffffff] text-[50px] md:text-[100px] text-bold text-center pt-[100px] mb-[50px]'> TOKENOMICS</p>
        
        <div>
            {/* 4 elemets with flex wrap */}
            <div className='flex md:flex-row flex-col w-full justify-center gap-6  items-center pb-10 px-[10%] '>
                    <img src={require('../../Assets/t1.png')}/>
                    <img src={require('../../Assets/t2.png')}/>
            </div>
            <div className='flex md:flex-row flex-col w-full justify-center gap-6  items-center pb-10 px-[10%] '>
                    <img src={require('../../Assets/t3.png')}/>
                    <img src={require('../../Assets/t4.png')}/>
            </div>
        </div>
    </div>
  )
}

export default Token